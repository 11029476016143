<div
  class="spot-modal job-status--modal"
>
  <div
    class="spot-modal--header"
    data-qa-selector="job-status--header"
  >{{ title }}</div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body spot-container">
    <div class="loading-indicator--location"
         data-indicator-name="modal">
      <div class="status-icon-wrapper" *ngIf="!isLoading && statusIcon">
        <span [ngClass]="statusIcon" class="icon-big"></span>
      </div>
    </div>
    <div>
      <span class="job-status--modal-main-message" [textContent]="message"></span>
      <span [hidden]="!downloadHref">
        {{ text.download_starts }}
        <a
          #downloadLink
          download
          target="_blank"
          [textContent]="text.click_to_download"
          [attr.href]="downloadHref"
        ></a>
      </span>
      <ng-container *ngIf="htmlContent">
        <div
          class="job-status--modal-additional-content"
          [innerHTML]="htmlContent"
        ></div>
      </ng-container>
      <ng-container *ngIf="payload?.errors">
        <h3 class="job-status--modal-additional-errors" [textContent]="text.errors"></h3>
        <ul *ngFor="let error of payload.errors">
          <li [textContent]="error"></li>
        </ul>

        <p *ngIf="payload?.redirect">
          <span [textContent]="text.redirect_errors"></span>
          <a [textContent]="text.redirect_link"
             [attr.href]="payload.redirect">
          </a>
        </p>
      </ng-container>
    </div>
  </div>
</div>
