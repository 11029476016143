<div
  class="spot-modal loading-indicator--location"
  data-indicator-name="modal"
>
  <div class="spot-modal--header">{{text.title}}</div>

  <div class="spot-divider"></div>

  <div class="ngdialog-body spot-modal--body spot-container">

    <op-enterprise-banner
      *ngIf="eeShowBanners"
      [linkMessage]="text.upsale_link"
      [textMessage]="text.upsale_text"
      [moreInfoLink]="text.more_info_link"
      class="grid--add-widget-ee"
      opReferrer="grids#add-widget"
    ></op-enterprise-banner>

    <ul class="spot-list">
      <li
        *ngFor="let widget of selectable;trackBy: trackWidgetBy"
        class="spot-list--item"
        data-qa-selector="op-grid--addable-widget">
        <button
          type="button"
          class="spot-list--item-action"
          (click)="select($event, widget)" 
          [id]="widget.identifier"
          [textContent]="widget.title"
        ></button>
      </li>
    </ul>
  </div>

  <div class="spot-action-bar hidden-for-mobile">
    <div class="spot-action-bar--right">
      <button
        type="button"
        class="button button_no-margin spot-modal--cancel-button spot-action-bar--action"
        (click)="closeMe()"
        >
        {{ text.cancel_button }}
     </button>
    </div>
  </div>
</div>
